// @mui
import { Typography, Box } from '@mui/material';
// hooks
import { useAuthContext } from '../../auth/useAuthContext';
// layouts
import LoginLayout from '../../layouts/login';
// forms
import AuthLoginForm from './AuthLoginForm';
// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();

  return (
    <LoginLayout>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', width: '480px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Sign in to Gold Investments
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
        </Box>
      </Box>

      <AuthLoginForm />
    </LoginLayout>
  );
}
