import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';

import { BUCKET_NAME, IMAGE_BASE_URL } from 'src/config';

// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();

  return (
    <Link to={PATH_DASHBOARD.user.account} component={RouterLink} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar
          src={`${IMAGE_BASE_URL}/${BUCKET_NAME}/${user?.image}`}
          alt={user?.first_name}
          name={user?.first_name}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {`${user?.first_name} ${user?.last_name}`}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {user?.account_type === 'EXTERNAL' ? user?.company_name : user?.account_type}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
