import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useController, Controller } from 'react-hook-form';
import { FormHelperText } from '@mui/material';

import { UploadAvatar, Upload, UploadBox } from '../upload';
import assert from '../../utils/assert';
import { buildImageURL } from '../../config';

// ----------------------------------------------------------------------

RHFUploadAvatar.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadAvatar({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const isError = !!error && !field.value;

        return (
          <div>
            <UploadAvatar
              accept={{
                'image/*': [],
              }}
              error={isError}
              file={field.value}
              {...other}
            />

            {isError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

RHFUploadBox.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadBox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const isError = !!error && !field.value?.length;

        return <UploadBox error={isError} files={field.value} {...other} />;
      }}
    />
  );
}

// ----------------------------------------------------------------------

RHFUpload.propTypes = {
  name: PropTypes.string,
  multiple: PropTypes.bool,
};

export function RHFUpload({ name, multiple, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const isErrorWithSingle = !!error && !field.value;

        const isErrorWithMultiple = !!error && !field.value?.length;

        return multiple ? (
          <Upload
            multiple
            accept={{ 'image/*': [] }}
            files={field.value}
            error={isErrorWithMultiple}
            helperText={
              isErrorWithMultiple && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        ) : (
          <Upload
            accept={{ 'image/*': [] }}
            file={field.value}
            error={isErrorWithSingle}
            helperText={
              isErrorWithSingle && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

// -------

RHFUpload.propTypes = {
  name: PropTypes.string,
  multiple: PropTypes.bool,
  helperText: PropTypes.node,
};

export function RHFUploads({ name, multiple, helperText, ...other }) {
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  const handleDrop = useCallback(
    (files) => {
      const newFiles = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      if (multiple) {
        const existingFiles = field.value ?? [];
        const updatedFiles = [...existingFiles, ...newFiles];

        field.onChange(updatedFiles);
      } else {
        assert(newFiles.length === 1, 'A field with `multiple` set to false should not have multiple new files');

        field.onChange(newFiles[0]);
      }
    },
    [field, multiple]
  );

  const handleRemoveFile = useCallback(
    (inputFile) => {
      if (!field.value) {
        return;
      }

      if (multiple) {
        const filtered = field.value.filter((file) => file !== inputFile);
        URL.revokeObjectURL(field.value.find((file) => file === inputFile).preview);
        field.onChange(filtered);
      } else {
        field.onChange(null);
      }
    },
    [field, multiple]
  );

  const handleRemoveAllFiles = useCallback(() => {
    if (!field.value) {
      return;
    }

    if (multiple) {
      field.value.forEach((file) => URL.revokeObjectURL(file.preview));
      field.onChange([]);
    } else {
      URL.revokeObjectURL(field.value.preview);
      field.onChange(null);
    }
  }, [field, multiple]);

  const value = useMemo(() => {
    const parseValue = (value) => (value instanceof File ? value : buildImageURL(value));

    return field.value && (multiple ? field.value.map(parseValue) : parseValue(field.value));
  }, [multiple, field.value]);

  return (
    <Upload
      multiple={multiple}
      accept="*/*"
      files={multiple ? value : null}
      file={multiple ? null : value}
      error={!!error}
      helperText={
        (!!error || helperText) && (
          <FormHelperText error={!!error} sx={{ px: 2 }}>
            {error ? error?.message : helperText}
          </FormHelperText>
        )
      }
      onDrop={handleDrop}
      onRemove={handleRemoveFile}
      onRemoveAll={handleRemoveAllFiles}
      {...other}
    />
  );
}
