import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import numeral from 'numeral';

RHFTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  formatNumber: PropTypes.bool,
};

export default function RHFTextField({ name, helperText, formatNumber, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const formatValue = (value) => {
          if (!formatNumber) return value;
          return value ? numeral(value).format('0,0') : '';
        };

        const parseValue = (value) => {
          if (!formatNumber) return value;
          return value.replace(/,/g, '');
        };

        return (
          <TextField
            {...field}
            fullWidth
            value={formatValue(field.value)}
            onChange={(event) => {
              const newValue = parseValue(event.target.value);
              field.onChange(newValue);
            }}
            error={!!error}
            helperText={error ? error?.message : helperText}
            {...other}
          />
        );
      }}
    />
  );
}
