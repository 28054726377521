// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components

import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  settings: icon('ic_settings'),

  user: icon('ic_users'),

  menuItem: icon('ic_menu_item'),

  dashboard: icon('ic_dashboard'),
  clients: icon('ic_client'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------

  {
    subheader: 'general',
    items: [
      { title: 'overview', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'clients', path: PATH_DASHBOARD.client.list, icon: ICONS.clients },
    ],
  },

  {
    subheader: 'settings',
    items: (user) => {
      switch (user.account_type) {
        case 'ADMIN':
          return [
            {
              title: 'users',
              path: PATH_DASHBOARD.user.list,
              icon: ICONS.user,
            },
            { title: 'account', path: PATH_DASHBOARD.user.account, icon: ICONS.settings },
          ];
        case 'STAFF':
        case 'EXTERNAL':
          return [{ title: 'account', path: PATH_DASHBOARD.user.account, icon: ICONS.settings }];
        default:
          return [];
      }
    },
  },
];

export default navConfig;
